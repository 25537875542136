import {getCoords} from './utils';

const tabs = (navsSelector, tabsSelector) => {
    const navs = document.querySelector(`.${navsSelector}`);
    const tabs = document.querySelector(`.${tabsSelector}`);

    const toggleActive = (element) => {
        element.classList.toggle('active');
    }

    const switchTabs = (e) => {
        // Если таб активен ничего не делаем
        if (e.target.classList.contains('active')) {
            // console.log('Таб уже активен');
            return true;
        }
        if (e.target.classList.contains(`${navsSelector}__item`)) {
            // Переключение навигации
            toggleActive(navs.querySelector('.active'));
            toggleActive(e.target);
            // Переключение табов
            toggleActive(tabs.querySelector('.active'));
            const tabNumber = e.target.dataset.number;
            const newTab = tabs.querySelector(`[data-number="${tabNumber}"]`)
            toggleActive(newTab);
            window.scrollTo(0, getCoords(newTab).top)
        }
        if (e.target.parentElement.classList.contains(`${navsSelector}__item`)) {
            // Переключение навигации
            toggleActive(navs.querySelector('.active'));
            toggleActive(e.target.parentElement);
            // Переключение табов
            toggleActive(tabs.querySelector('.active'));
            const tabNumber = e.target.parentElement.dataset.number;
            const newTab = tabs.querySelector(`[data-number="${tabNumber}"]`)
            toggleActive(newTab);
            window.scrollTo(0, getCoords(newTab).top)
        }
    }
    if (navs) {
        navs.addEventListener('click', switchTabs, false);
    } else {
        // console.log('Табов не обнаружено');
    }
}

export default tabs;